<template>
  <div>
    <v-tabs background-color="transparent" grow v-model="tab">
      <v-tab>Usage</v-tab>
      <v-tab>Billing</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="background-color: transparent">
      <v-tab-item> <usage-screen /> </v-tab-item>
      <v-tab-item>
        <billing-screen />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import BillingScreen from "../components/BillingScreen.vue";
import UsageScreen from "../components/UsageScreen.vue";
export default {
  components: { BillingScreen, UsageScreen },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
