<template>
  <div class="mt-5">
    <v-row class="align-center top-note mb-3 pl-3">
      Total Bills: {{ bills ? bills.length : "..." }}
      <v-spacer />
    </v-row>
    <load-handler
      :loading="loading"
      :error-message="errorMessage"
      :empty-message="emptyMessage"
    >
      <v-row>
        <v-col md="4" lg="3" v-for="(bill, index) in bills" :key="index">
          <bill-card :bill="bill" />
        </v-col>
      </v-row>
    </load-handler>
  </div>
</template>
<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import LoadHandler from "../components/LoadHandler.vue";
import "firebase/firestore";
import BillCard from "./BillCard.vue";

export default {
  components: {
    LoadHandler,
    BillCard,
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      emptyMessage: "",
      bills: [],
    };
  },
  computed: {
    ...mapState({
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
  mounted() {
    this.fetchbills();
  },
  methods: {
    async fetchbills() {
      try {
        this.loading = true;
        this.errorMessage = "";
        this.emptyMessage = "";

        console.log(this.currentOrganization);
        // bb/orgid/bills/

        firebase
          .firestore()
          .collection("Billing")
          .where("org_id", "==", this.currentOrganization.id)
          .onSnapshot((snapshot) => {
            if (snapshot.empty) {
              this.emptyMessage = "No Bill found";
            } else {
              const bills = snapshot.docs.map((doc) => doc.data());
              this.bills = bills;
            }
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.errorMessage = error.message
          ? error.message
          : "Error fetching bills";
      }
    },
  },
};
</script>
