<template>
  <v-card class="pa-6" elevation="0">
    <div class="d-flex flex-column justify-center align-center">
      <span class="amount"> {{ bill.currency }} {{ bill.amount }}</span>
      <span class="date"> {{ formattedDateCreated }} </span>
      <v-chip class="ma-3" x-small :color="chipColor" text-color="white">
        {{ bill.paid ? "PAID" : "UNPAID" }}
      </v-chip>
    </div>
  </v-card>
</template>
<script>
import { format } from "date-fns";
export default {
  props: {
    bill: {
      type: Object,
      require: true,
    },
  },

  computed: {
    chipColor() {
      return this.bill.paid ? "#4ce051" : "#e04c4c";
    },
    formattedDateCreated() {
      return format(this.bill.bill_date.toDate(), "do MMM; yyyy");
    },
  },
};
</script>
